



















































































































































































































































































.mobile
  .code
    display none
  .q-card
    width 100vw
    box-shadow none

.code
  height 25vh
  width 100%
  font-size 8vmax
  color rgba(255, 255, 255, .6)
  overflow hidden
  background-image url('~assets/backgrounds/ferry.jpg')
  background-position: 0 30%;
  background-size: cover;
  text-overflow: clip;

.column.container
  margin-top -64px

.q-card
  width 80vw
  max-width 600px

.center
  justify-content center
  display flex

.q-page
  display flex
  width 100%
  flex-direction column
  align-items center
  background white

.ferry-form
  width 100%
  display grid
  grid-template-columns 36px auto 36px
  grid-auto-rows auto
  align-items center

.routes-select
  width 100%
  border 1px solid #ccc
  border-radius 20px
  height 56px
  padding 6px
  margin 8px 0
  position relative
  line-height 28px

#routes-list
  height auto

#swap-button
  background white
  border none
  width 24px
  height 24px
  margin-left 12px
  grid-row 1 / 3
  grid-column 3 / span 1

.close
  width 100%
  position sticky

@keyframes dropdown {
  from { height: 0px }
  to { height: max-height }
}

.dropdown-enter-active
  transition height 0.5s

.dropdown-leave-active
  transition height 0.5s

.dropdown-enter-to
  animation dropdown 0.5s

.dropdown-leave-to
  animation dropdown 0.5s reverse

.list
  position: absolute;
  background: white;
  z-index: 100;
  width: 90%
  height: 60vh
  max-width 420px
  left: 5%
  top 90px
  box-shadow: 0 1px 5px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12);

.list-inner
  height: 100%

.span-three
  grid-column 1 / span 3

.oneway-return
  grid-column 1 / span 3
  display grid
  grid-template-columns 1fr 1fr
  align-items center
  label
    display flex
    align-items center
    justify-content center
    color black

input
  &[type="radio"]
    margin 12px
    width: 20px;
    height: 20px;
    border-radius 15px
    border: 2px solid #666
    &:checked
      border: 2px solid convert(brandColor)
      &:after
        width: 14px;
        height: 14px;
        bottom 2px
        left 1px
        border-radius: 15px;
        position: relative;
        background-color: convert(brandColor)
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white

  .timetable
    text-decoration underline !important
